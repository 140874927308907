import PropTypes from "prop-types";
import React, { useState } from "react";
import handArrowDown from "../assets/svg/HandArrowDown.svg";
import frameSent from "../assets/svg/Message.svg"; // Sent icon
import frameError from "../assets/svg/MessageFail.svg"; // Error icon

import TextArea from "./TextArea";
import Input from "./Input";
import Button1 from "./button1";

import MacosWindowHeader from "./MacosWindowHeader";



interface Props {
  mode: "dark" | "light";
  className?: string;
}

// Utility function to select the correct icon based on the message state
const getMessageIcon = (state: "sent" | "error"): string => {
  return state === "sent" ? frameSent : frameError;
};

const ContactForm: React.FC<Props> = ({ mode, className }) => {
  const isLightMode = mode === "light";
  const [formState, setFormState] = useState<"default" | "sent" | "error">("default");

  // Function to handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission
    const form = event.currentTarget;
    const formData = new FormData(form);

    try {
      const response = await fetch("https://formspree.io/f/xpzokrbz", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setFormState("sent");
      } else {
        setFormState("error");
      }
    } catch (error) {
      setFormState("error");
      console.error("Error submitting form:", error);
    }
  };

  return (
<div
  className={`border flex flex-col min-h-screen overflow-hidden rounded-xl relative
    ${isLightMode ? "bg-[#f6f6f6] border-[#ebebeb] shadow-[0px_12px_30px_#0000000f]" : "bg-[#181818] border-[#383737] shadow-[0px_19px_30px_#00000033]"}
    ${formState === "default" ? "items-start" : "items-center justify-center gap-4"}
    ${className || ""}`}
  style={{ height: "100vh" }} // Ensure full viewport height
>
  {formState === "default" ? (
    <FormContent mode={mode} onSubmit={handleSubmit} />
  ) : (
    <MessageContent mode={mode} state={formState} />
  )}
</div>

  );
};

// FormContent component to display the form
const FormContent = ({
  mode,
  onSubmit,
}: {
  mode: "dark" | "light";
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}) => (

  <form
    onSubmit={onSubmit}
    className="w-full flex flex-col gap-8 px-8 py-4 flex-grow min-h-screen"
  >
    <MacosWindowHeader className="w-full mt-0" mode={mode} title="New message" />
  
    <InputGroup mode={mode} />
  
    <TextArea
      className="!w-full flex-grow"
      mode={mode}
      placeholder={mode === "dark" ? "Write your message here" : "Type your message here"}
      name="message"
    />
  
    <div className="w-full mt-auto flex justify-end">
      <Button1
        text="Send"
        mode={mode} // Pass mode for styling
        type="submit" // Submit the form
        className="w-[150px]" // Optional: set the width
      />
    </div>
  </form>
  

);


// InputGroup for the email, name, and subject fields
const InputGroup = ({ mode }: { mode: "dark" | "light" }) => (
  <div className="w-full flex flex-col items-start gap-4">
    <Input
      className="!w-full"
      label="Email:"
      mode={mode}
      placeholder="Enter your email address"
      showDivider={true}
      name="email"
    />
    <Input
      className="!w-full"
      label="Name:"
      mode={mode}
      placeholder="Enter your name"
      showDivider={true}
      name="name"
    />
    <Input
      className="!w-full"
      label="Subject:"
      mode={mode}
      placeholder="Enter subject"
      showDivider={false}
      name="subject"
    />
  </div>
);

// MessageContent for success or error states
const MessageContent = ({
  mode,
  state,
}: {
  mode: "dark" | "light";
  state: "sent" | "error";
}) => (
  <div className="relative flex flex-col items-center justify-center min-h-[690px] w-full px-8 py-8">
    {/* Centered Content */}
    <div className="flex flex-col items-center gap-4 justify-center flex-grow -mt-4">
      {/* Icon */}
      <img
        className="w-12 h-12 mb-2"
        alt={state === "sent" ? "Message Sent Icon" : "Message Error Icon"}
        src={getMessageIcon(state)}
      />

      {/* Main Message */}
      <h2 className={`text-3xl font-semibold ${mode === "light" ? "text-[#181818]" : "text-white"}`}>
        {state === "sent" ? "Message Sent!" : "Cannot send message :("}
      </h2>

      {/* Secondary Message */}
      <p
        className={`${
          mode === "light" ? "text-[#7f7f7f]" : "text-[#c4c4c4]"
        } text-lg mt-2 leading-relaxed text-center`}
      >
        {state === "sent"
          ? "Thanks for taking the time to write me, I’ll reply to you as soon as possible."
          : "An error occurred. Please try again later."}
      </p>
    </div>

    {/* Bottom Content */}
    <div className="absolute bottom-8 flex flex-col items-center">
      <p
        className={`${
          mode === "light" ? "text-[#7f7f7f]" : "text-[#c4c4c4]"
        } font-medium text-sm text-center mb-4`}
      >
        {state === "sent"
          ? "In the meantime, follow me on these platforms below"
          : "Or try reaching out on these platforms"}
      </p>
      <img className="w-[70px] h-auto" alt="Hand pointing downwards" src={handArrowDown} />
    </div>
  </div>
);

ContactForm.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"] as const).isRequired, // Use "as const" to assert the literal type
  className: PropTypes.string,
};

export default ContactForm;
