import React from "react";


interface MacosControlsProps {
    className?: string;
    }

const MacosControls: React.FC<MacosControlsProps> = ({ className }) => {
  return (
    <div className="inline-flex h-[22px] items-center gap-2 relative">
      <div className="relative w-3 h-3 bg-[#f63636] rounded-md border border-solid border-[#d52828]" />

      <div className="relative w-3 h-3 bg-[#f6c036] rounded-md border border-solid border-[#cea335]" />

      <div className="relative w-3 h-3 bg-[#68f636] rounded-md border border-solid border-[#52cc27]" />
    </div>
  );
};

export default MacosControls;