import React from "react";
import BackgroundBlur from "../components/BackgroundBlur";
import InfoSection from "../components/InfoSection";
import SocialLinks from "../components/SocialLinks";
import ContactForm from "../components/ContactForm";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface InfoPageProps {
  mode: "dark" | "light";
}

const InfoPage: React.FC<InfoPageProps> = ({ mode }) => {
  return (
    <div className={`relative min-h-screen flex flex-col items-center ${mode === "dark" ? "bg-[#0d0d0d] text-white" : "bg-[#f9f9f9] text-black"}`}>
      
      <BackgroundBlur />

      <div className="container mx-auto py-32 flex flex-col items-center max-w-screen-lg space-y-16">
        
        {/* Info Sections */}
        <div className="w-full mb-8">
          <Header
            mode={mode}
            size="web"
            text="About Me"
            subtext="Whoa! You found me! 🎉"
          />
        </div>

        <div className="flex flex-col gap-16 w-full">
          <InfoSection title="WHO I AM" description="I'm Lars Oberhofer, a creative Usability Engineer, looking for the next big challenge. Originally from a sunny place at the southern German wine street, now in the cold but beautiful Finland." />
          <InfoSection title="WHAT I DO" description="My work is connecting different passions of mine, from engineering, research, and Human-Computer Interaction. Enjoy making things better, with a human-centered perspective, I'm passionate about pushing boundaries, AI, and leveraging my background in Electrical Engineering and Computer Science." />
          <InfoSection title="WHAT I DID" description="I earned my BSc in Electrical Engineering from the University of Twente in the Netherlands and pursued a dual master’s degree in Human-Computer Interaction at Paris-Saclay and Aalto University, graduating Cum Laude. About this site: It’s built with React, styled with Tailwind CSS, animated with Framer Motion, and entirely designed in Figma. "/>
        </div>

        {/* Contact Form Section */}
        <div className="w-full max-w-screen-lg space-y-16">
          <Header
            mode={mode}
            size="web"
            text="Get in Touch"
            subtext="Let’s build something awesome."
          />
          <ContactForm mode={mode} /> {/* Only passing `mode` here */}
        </div>

        {/* Social Links */}
        <SocialLinks mode={mode}/>
      </div>
      {/* Footer */}
      <Footer mode={mode} className="w-full mt-auto" />

    </div>
    
  );
};

export default InfoPage;
