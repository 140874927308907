import React from "react";


interface InfoSectionProps {
    title: string;
    description: string;
  }
  



const InfoSection: React.FC<InfoSectionProps> = ({ title, description }) => {
    return (
      <div className="flex flex-col items-start gap-2">
        <div className="text-[#7f7f7f78] text-base font-semibold">{title}</div>
        <div className="text-lg text-[#7d7d7d]">{description}</div>
      </div>
    );
  };

  
  export default InfoSection;