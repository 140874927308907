import PropTypes from "prop-types";
import React from "react";


import BagFill from "../assets/svg/Bag-Fill.svg";
import BagOutline from "../assets/svg/Bag.svg";
import CameraFill from "../assets/svg/Camera-Fill.svg";
import CameraOutline from "../assets/svg/Camera.svg";
import HomeFill from "../assets/svg/HomeFill.svg";
import HomeOutline from "../assets/svg/Home-Outline.svg";
import MessageFill from "../assets/svg/Message-Fill.svg";
import MessageOutline from "../assets/svg/Message.svg";


import { Link, useLocation } from "react-router-dom";



interface Props {
  mode: "dark" | "light";
  className?: string;
  frameClassName?: string;
}

export const Tabbar = ({ mode, className, frameClassName }: Props): JSX.Element => {
  const location = useLocation();
  const isDarkMode = mode === "dark";

  // Function to check if a tab should be highlighted
  const isActive = (path: string) => location.pathname === path;

  return (
    <div
      className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 p-4 w-[358px] rounded-xl h-[60px] flex items-center justify-around ${
        isDarkMode ? "bg-[#18171c99] border-[#27262b61]" : "bg-basicwhite border-darkwhite-10"
      } ${className}`}
    >
      <div className={`flex items-center justify-around w-full ${frameClassName}`}>
        {/* Home Tab */}
        <Link
          to="/"
          className={`flex flex-1 items-center justify-center h-10 rounded-xl cursor-pointer ${
            isActive("/") ? "bg-[#ffffff1a]" : ""
          }`}
        >
          <img
            src={isActive("/") ? HomeFill : HomeOutline}
            alt="Home Icon"
            className="w-6 h-6"
          />
        </Link>

        {/* Bag Tab */}
        <Link
          to="/creations"
          className={`flex flex-1 items-center justify-center h-10 rounded-xl cursor-pointer ${
            isActive("/creations") ? "bg-[#ffffff1a]" : ""
          }`}
        >
          <img
            src={isActive("/creations") ? BagFill : BagOutline}
            alt="Bag Icon"
            className="w-6 h-6"
          />
        </Link>

        {/* Camera Tab */}
        <Link
          to="/photography"
          className={`flex flex-1 items-center justify-center h-10 rounded-xl cursor-pointer ${
            isActive("/photography") ? "bg-[#ffffff1a]" : ""
          }`}
        >
          <img
            src={isActive("/photography") ? CameraFill : CameraOutline}
            alt="Camera Icon"
            className="w-6 h-6"
          />
        </Link>

        {/* Message Tab */}
        <Link
          to="/info"
          className={`flex flex-1 items-center justify-center h-10 rounded-xl cursor-pointer ${
            isActive("/info") ? "bg-[#ffffff1a]" : ""
          }`}
        >
          <img
            src={isActive("/info") ? MessageFill : MessageOutline}
            alt="Message Icon"
            className="w-6 h-6"
          />
        </Link>
      </div>
    </div>
  );
};

Tabbar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]),
  className: PropTypes.string,
  frameClassName: PropTypes.string,
};

export default Tabbar;
