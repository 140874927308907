import React from "react";
import { useParams } from "react-router-dom";
import BackgroundBlur from "../components/BackgroundBlur";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BachelorThesisContent from "../components/BachelorThesisContent";
import MasterThesisContent from "../components/MasterThesisContent";

interface ProjectPageLayoutProps {
  mode: "dark" | "light";
}

const ProjectPageLayout: React.FC<ProjectPageLayoutProps> = ({ mode }) => {
  const { id } = useParams<{ id: string }>(); // Get dynamic project ID from URL

  const renderProjectContent = () => {
    switch (id) {
      case "bachelor-thesis-evtol":
        return <BachelorThesisContent mode={mode} />;
      case "master-thesis-abb":
        return <MasterThesisContent mode={mode} />;
        default:
          return (
              <div className="text-center text-white">
                  <h1>Project Not Yet Published</h1>
                  <p>The project you are looking for will be available shortly.</p>
              </div>
          );
    }
  };

return (
  <div className={`min-h-screen flex flex-col ${mode === "dark" ? "bg-[#0d0d0d]" : "bg-[#f9f9f9]"}`}>
    {/* Background Blur */}
    <BackgroundBlur />

    {/* Header */}
    <div className="container mx-auto py-32 flex flex-col items-start max-w-screen-lg">
      <Header mode={mode} size="web" text="Selected Work" subtext="Project Details" />
    </div>

    {/* Divider */}
    <div
      className={`w-full border-t ${
        mode === "dark" ? "border-[#eeeeee29]" : "border-[#dddddd]"
      }`}
    ></div>

    {/* Dynamic Project Content */}
    <div
      className={`flex-1 px-6 md:px-24 py-12 mb-12 ${ // Added mb-12 for space
        mode === "dark" ? "bg-transparent text-[#7f7f7f]" : "bg-[#f9f9f9] text-[#181818]"
      }`}
    >
      {renderProjectContent()}
    </div>

    {/* Footer */}
    <Footer mode={mode} />
  </div>
);

  
};

export default ProjectPageLayout;
