import React from "react";
import { motion, useScroll } from "framer-motion";
import logoABB from "../assets/svg/projects/ABB.svg"; // Logo import
import GifImage from "../assets/GIFS/IMG_6969.gif";

import ABBOffice from "../assets/JPG/ABB/ABB_office.jpg";

import Dynafin from "../assets/JPG/ABB/Dynafin.jpg";
import epicycloidal from "../assets/JPG/ABB/epicycloidal.jpg";
import trochoidal from "../assets/JPG/ABB/trochoidal.jpg";
import DOIT from "../assets/JPG/ABB/DOIT.jpg";
import highlevel1 from "../assets/JPG/ABB/highlevel.jpg";
import instrumentalInteractionModel1 from "../assets/JPG/ABB/instrumental_interaction_model.jpg";
import designDimensionsDiagram from "../assets/JPG/ABB/design_dimensions_diagram.jpg";
import workshop1 from "../assets/JPG/ABB/workshop.jpg";
import querschnitt1 from "../assets/JPG/ABB/querschnitt.jpg";
import axlever2replica1 from "../assets/JPG/ABB/AXlever2Replica.jpg";
import allpartsaxlever21 from "../assets/JPG/ABB/allpartsaxlever2.jpg";
import highlevelWebsocket1 from "../assets/JPG/ABB/highlevel_websocket.jpg";
import highlevelPhysical1 from "../assets/JPG/ABB/highlevel_physical.jpg";


import maneuverMode1 from "../assets/JPG/ABB/maneuver-mode.jpg";
import seaMode1 from "../assets/JPG/ABB/sea-mode.jpg";
import controlView1 from "../assets/JPG/ABB/control-view.jpg";
import proto2 from "../assets/JPG/ABB/proto.jpg";










interface MasterThesisCardProps {
  mode: "dark" | "light";
}

const MasterThesisCard: React.FC<MasterThesisCardProps> = ({ mode }) => {
  const bgColor = mode === "dark" ? "bg-[#0d0d0d]" : "bg-[#f9f9f9]";
  const textColor = mode === "dark" ? "text-[#7f7f7f]" : "text-[#181818]";
  const headingColor = mode === "dark" ? "text-white" : "text-[#181818]";
  const borderColor = mode === "dark" ? "border-[#383737]" : "border-[#ebebeb]";

  const { scrollYProgress } = useScroll();




  return (

    <>
    {/* Scroll Progress Bar */}
    <motion.div
      className="fixed top-0 left-0 h-full w-2 bg-orange-500 z-50"
      style={{ scaleY: scrollYProgress, transformOrigin: "top" }}
    />

    {/* Main Content */}
    <motion.div
      className=" mx-auto"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
<div

  className={`w-full max-w-[1024px]  mx-auto rounded-2xl overflow-hidden border ${borderColor} ${
    mode === "dark" ? "shadow-card-dark" : "shadow-card-light"
  } ${bgColor}`}
>

  {/* Header Section */}

  <div className="flex flex-col gap-8 p-[60px]">
    {/* Logo and Title Section */}
    <div className="flex items-start gap-6">
      {/* Logo */}
      <div className="w-[70px] h-[70px] flex-shrink-0 rounded-2xl overflow-hidden flex items-center justify-center bg-[#383737] shadow-md">
        <img
          src={logoABB}
          alt="Volocopter Logo"
          className="object-contain w-full h-full"
        />
      </div>

      {/* Title */}
      <div className="flex-1">
        <h1
          className={`font-extrabold text-4xl leading-[1.2] ${headingColor}`}
        >
          Enhancing Maritime Navigation:
User-Centered Interface Design and Interaction
Modelling for a Next-Generation Cyclorotor
Propulsion System
        </h1>
      </div>
    </div>
  </div>







      {/* Content Sections */}
      <div className={`flex flex-col  pb-[60px] px-[60px] ${textColor}`}>












      <section className="mt-12">
      <div className="flex w-full max-w-[904px] items-start gap-[30px] mx-auto">
    {/* Text Section */}
    <div className="flex-1">
      <p className="font-bold text-[#7f7f7f] text-[15px] leading-[23.7px] mb-2">
        Overview
      </p>
      <p className="font-normal text-[#7f7f7f] text-[15px] leading-[23.7px]">
        This master's thesis investigated user-centered interface design and
        interaction modeling for ABB's next-generation Dynafin™ cyclorotor propulsion
        system. By employing participatory design workshops, semi-structured interviews,
        and iterative prototyping, the research explored challenges in aligning user
        mental models with system conceptual models to ensure operational safety and
        efficiency. Key developments included a prototype azimuth lever and graphical
        user interface, focusing on situational awareness, usability, and control
        precision. Findings emphasized the importance of cognitive load management and
        user-centered methodologies in maritime navigation systems.
      </p>
    </div>

    {/* Image Section */}
    <img
      className="w-[160.89px] h-[214px] object-cover"
      alt="ABB office"
      src={ABBOffice}
    />
  </div>
</section>

    









{/* What I Did Section */}
<section className="mt-12">
<h2
    className={`text-lg font-semibold mb-4 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
    ABB'S DYNAFIN TECHNOLOGY
  </h2>

  <div className="flex w-full max-w-[906px] gap-2.5 items-start relative">
    {/* Left Image */}
    <img
      className="w-[453px] h-[277px] object-cover"
      alt="Dynafin"
      src={Dynafin}
    />

    {/* Right Column */}
    <div className="flex flex-col w-[414px] gap-2.5">
      <img
        className="w-full h-[132px] object-cover"
        alt="Trochoidal"
        src={trochoidal}
      />

      <img
        className="w-full h-[135px] object-cover"
        alt="Epicycloidal"
        src={epicycloidal}
      />
    </div>
  </div>

  {/* Paragraph Section */}
  <p className="mt-6 text-[#7f7f7f] text-[15px] leading-[23.7px]">
    <span className="font-bold">Innovative new Cyclorotor Technology</span>
    <br />
    ABB’s Dynafin™ cyclorotor system introduces groundbreaking propulsion
    technology, offering trochoidal blade paths through advanced individual
    blade adjustment mechanisms. This innovation enhances precision,
    efficiency, and maneuverability beyond what existing technologies can
    achieve. While promising significant improvements in operational
    efficiency, the technology also introduces additional complexity,
    potential limitations, and knowledge gaps for users, emphasizing the need
    for intuitive and user-centered interface design.
  </p>
</section>

















<section className="mt-12">

<h2
    className={`text-center text-lg font-semibold mb-4 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
    METHODOLOGY - DESIGN OF INTERACTIVE SYSTEMS
  </h2>



  <div className="flex w-[906px] items-start gap-[30px] relative flex-[0_0_auto] ml-[-1.00px] mr-[-1.00px]">
  {/* Text Section */}
  <div className="relative flex-1 self-stretch mt-[-1.00px]">
    <p className="[font-family:'Inter-Regular',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px] mb-4">
      The development followed Wendy Mackay’s Design of Interactive Systems framework, structured into four iterative phases:
    </p>

    <ul className="list-disc pl-6 [font-family:'Inter-Regular',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px] space-y-2">
      <li>
        <span className="font-bold">Who Are We Designing For?</span> Conducted user research through story and semi-structured interviews to understand operator needs and challenges.
      </li>
      <li>
        <span className="font-bold">What Is Possible for Us?</span> Explored design constraints and opportunities via discovery workshops and expert discussions.
      </li>
      <li>
        <span className="font-bold">What Should Our Design Be?</span> Synthesized findings into design concepts, refined through operator feedback.
      </li>
      <li>
        <span className="font-bold">Does Our Design Work?</span> Validated usability and effectiveness through iterative user and prototype testing.
      </li>
    </ul>
  </div>

  {/* Image Section */}
  <img
    className="relative w-[364px] h-[362px] object-cover"
    alt="Doit"
    src={DOIT}
  />
</div>

  
</section>





<section className="mt-12">
<h2
    className={`text-lg font-semibold mb-4 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
    RESEARCH PROCESS
  </h2>

  <div className="flex flex-col w-[904px] items-start justify-center gap-[30px] relative">
    {/* Top Section: Workshop Image and Text */}
    <div className="flex flex-col items-center justify-end gap-[30px] self-stretch w-full relative flex-[0_0_auto]">
      <div className="flex w-[906px] items-start justify-center gap-10 ml-[-1.00px] mr-[-1.00px] relative flex-[0_0_auto]">
        <img
          className="relative w-[580px] h-[331px] object-cover"
          alt="Workshop"
          src={workshop1}
        />
        <p className="relative flex-1 mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px]">
          <span className="font-bold">
            User-Centered Interface Design for Cyclorotor Propulsion Systems
            <br />
          </span>
          <span className="[font-family:'Inter-Regular',Helvetica]">
  <ul className="list-disc pl-6 space-y-2">
    <li>
      Researched and developed a next-generation user interface tailored to maritime professionals.
    </li>
    <li>
      Conducted user studies, including story interviews, participatory workshops, and prototype testing, to address real-world operational challenges.
    </li>
    <li>
      Prototyped an innovative azimuth lever and designed a graphical interface for the next-generation cyclorotor.
    </li>
  </ul>
</span>

        </p>
      </div>
    </div>

    {/* Middle Section: Design Dimensions Diagram */}
    <img
      className="relative w-[904px] h-[381.22px] object-cover"
      alt="Design dimensions"
      src={designDimensionsDiagram}
    />

    {/* Bottom Section: List with Supporting Images */}
    <div className="flex items-start gap-[30px] self-stretch w-full relative flex-[0_0_auto]">
      <div className="flex-1">
        <p className="relative flex-1 mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-[#7f7f7f] text-[15px] leading-[23.7px] mb-4">
          <span className="font-bold">
            Applying Design Thinking to Maritime Navigation
            <br />
          </span>
          <span className="[font-family:'Inter-Regular',Helvetica] text-[#7f7f7f]">
            Leveraged design methodologies and usability feedback to develop
            user-centered systems optimized for maritime operations.
          </span>
        </p>

        <ul className="list-disc pl-6 [font-family:'Inter-Regular',Helvetica] text-[#7f7f7f] text-[15px] leading-[23.7px] space-y-2">
          <li>
            Used Wendy Mackay’s DOIT methodology to iteratively refine designs
            with insights from user feedback.
          </li>
          <li>
            Integrated Shneiderman’s Eight Golden Rules and Don Norman’s Seven
            Principles to reduce cognitive load and user errors.
          </li>
          <li>
            Designed workflows and systems to enhance operational safety and
            efficiency in maritime environments.
          </li>
        </ul>
      </div>

      <div className="inline-flex flex-col items-start justify-center relative flex-[0_0_auto]">
        <img
          className="relative w-[426px] h-[105px] object-cover"
          alt="Instrumental"
          src={instrumentalInteractionModel1}
        />
        <img
          className="relative w-[426px] h-[255px] mt-[-13px] object-cover"
          alt="Highlevel"
          src={highlevel1}
        />
      </div>
    </div>
  </div>
</section>









<section className="mt-12">
<h2
    className={`text-lg font-semibold mb-4 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
    PROTOTYPE DEVELOPMENT
  </h2>

  <div className="flex flex-col items-start gap-[30px] relative">
    {/* Physical and Digital Prototypes */}
    <div className="w-[906px] gap-[30px] flex-[0_0_auto] mr-[-2.00px] flex items-start relative">
      <p className="relative flex-1 mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px]">
        <span className="font-bold">Physical and Digital Prototypes</span>
        <ul className="list-disc pl-6 mt-2">
          <li>
            Built a 3D-printed azimuth lever with tactile feedback for enhanced
            user control.
          </li>
          <li>
            Developed a digital interface for real-time feedback and interaction.
          </li>
          <li>
            Tested the system with maritime professionals under simulated
            conditions.
          </li>
        </ul>
      </p>
      <img
        className="relative w-[646px] h-[495px]"
        alt="Highlevel physical"
        src={highlevelPhysical1}
      />
    </div>

    {/* Interaction Design and Simulated Environment */}
    <div className="flex-wrap gap-[40px_40px] self-stretch w-full flex-[0_0_auto] flex items-start relative">
      <img
        className="relative w-[539px] h-[555px] object-cover"
        alt="Highlevel websocket"
        src={highlevelWebsocket1}
      />
      <div className="flex-col gap-[30px] flex-1 grow flex items-start relative">
        <p className="relative self-stretch mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px]">
          <span className="font-bold">Interaction Design</span>
          <ul className="list-disc pl-6 mt-2">
            <li>
              Created an Interaction Table mapping user actions to system
              responses for intuitive navigation and real-time feedback.
            </li>
            <li>
              Designed mechanisms for error prevention and recovery using
              insights from user studies.
            </li>
          </ul>
          <br />
          <span className="font-bold">Simulated Control Environment</span>
          <ul className="list-disc pl-6 mt-2">
            <li>
              Built a Graphical Interface Prototype showcasing thrust vectoring
              and blade adjustments.
            </li>
            <li>
              Explored future features like adaptive automation and customizable
              controls for next-gen cyclorotor systems.
            </li>
          </ul>
        </p>
      </div>
    </div>

    {/* Prototype Images */}
    <div className="justify-center gap-4 self-stretch w-full flex-[0_0_auto] flex items-start relative">
      <img
        className="relative w-[196px] h-[332px] object-cover"
        alt="Allpartsaxlever"
        src={allpartsaxlever21}
      />
      <img
        className="relative w-[367px] h-[332px] object-cover"
        alt="Axleverreplica"
        src={axlever2replica1}
      />
      <img
        className="relative w-[309px] h-[332px] object-cover"
        alt="Querschnitt"
        src={querschnitt1}
      />
    </div>
  </div>
</section>

































<section className="mt-12">
<h2
    className={`text-lg font-semibold mb-4 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
    PROTOTYPE DEMONSTRATION - A FULLY FUNCTIONAL AZIMUTH LEVER AND USER INTERFACE
  </h2>

  {/* Container for Explanation and Images */}
  <div className="flex flex-col items-start gap-[30px] relative">

    {/* Explanation Section */}
    <div className="flex-wrap gap-[15px_15px] self-stretch w-full flex-[0_0_auto] flex items-start relative">
      <p className="relative w-[904px] mt-[-1.00px] [font-family:'Inter-Regular',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px]">
        While designs remain confidential, visuals of current ABB marine UI
        systems provide context for the project&#39;s foundation.
        <br />
        A video showcases the azimuth lever prototype in action, excluding the
        new control and monitoring screens details due to confidentiality. The
        prototype communicates with the controllers and command post panels.
      </p>
    </div>

    {/* Images Section */}
    <div className="flex gap-[30px] self-stretch w-full">
      {/* Three Stacked Images */}
      <div className="flex flex-col w-[448px] gap-2.5">
        <img
          className="relative w-full h-[278px] object-cover"
          alt="Control view"
          src={controlView1}
        />
        <img
          className="relative w-full h-[278px] object-cover"
          alt="Sea mode"
          src={seaMode1}
        />
        <img
          className="relative w-full h-[278px] object-cover"
          alt="Maneuver mode"
          src={maneuverMode1}
        />
      </div>

      {/* Long Vertical Image */}
      <div className="flex w-[448px]">
      <img
  className="relative w-full h-[846px] object-cover"
  alt="Looping GIF"
  src={GifImage}
/>

</div>

    </div>
  </div>
</section>


<section className="mt-12">
<h2
    className={`text-lg font-semibold mb-4 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
    WHAT I LEARNED
  </h2>

  <div className="flex flex-col items-center justify-end gap-[30px] relative">
    {/* List of Learnings */}
    <div className="relative self-stretch [font-family:'Inter-Regular',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px]">
      <ul className="list-disc pl-6">
        <li>
          <span className="font-bold">Advanced HCI Design Techniques:</span> Applied iterative design methodologies to safety-critical systems.
        </li>
        <li>
          <span className="font-bold">Maritime UX/UI Expertise:</span> Gained specialized knowledge in aligning user mental models with complex control systems.
        </li>
        <li>
          <span className="font-bold">Cross-Disciplinary Collaboration:</span> Worked across engineering, design, and user experience domains to deliver practical solutions.
        </li>
        <li>
          <span className="font-bold">Research and Prototyping Skills:</span> Strengthened capabilities in user research, prototyping, and iterative refinement to address operational challenges.
        </li>
      </ul>
    </div>

    {/* Image of Prototype */}
    <img
      className="relative w-[626px] h-[635px] object-cover"
      alt="Prototype"
      src={proto2}
    />
  </div>
</section>
 












      </div>
    </div>
    </motion.div>
    </>
  );
};

export default MasterThesisCard;
