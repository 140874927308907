import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PhotographyPage from "./pages/PhotographyPage";
import InfoPage from "./pages/InfoPage";
import ProjectPage from "./pages/ProjectPage";
import ProjectPageLayout from "./pages/ProjectPageLayout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import "./global.css";
import Toggle from "./components/Toggle";
import LinksWeb from "./components/LinksWeb";
import Tabbar from "./components/Tabbar";
import CookieBanner from "./components/CookieBanner";

const App: React.FC = () => {
  // Default mode set to "dark"
  const [mode, setMode] = useState<"dark" | "light">("dark");
  const [fullscreen, setFullscreen] = useState(false);
  const location = useLocation();

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const handleFullscreenChange = (isFullscreen: boolean) => {
    setFullscreen(isFullscreen);
  };

  // Conditionally skip the cookie banner on the Privacy Policy page
  const showCookieBanner = location.pathname !== "/privacy-policy";

  return (
    <div className={`page-gradient ${mode === "dark" ? "bg-[#1e1e1e]" : "bg-[#f9f9f9]"}`}>
      {/* Show Cookie Banner if not on Privacy Policy */}
      {showCookieBanner && <CookieBanner />}

      {/* Toggle for Dark/Light Mode */}
      <Toggle onClick={toggleMode} mode={mode} hidden={fullscreen} />

      {/* LinksWeb for Desktop */}
      <div className="hidden sm:block fixed top-0 left-0 w-full z-50">
        <LinksWeb mode={mode === "dark" ? "light" : "dark"} hidden={fullscreen} />
      </div>

      {/* Tabbar for Mobile */}
      <div className="block sm:hidden fixed top-0 left-0 w-full z-50">
        <Tabbar mode={mode} />
      </div>

      {/* Routes */}
      <Routes>
        <Route path="/" element={<HomePage mode={mode} />} />
        <Route path="/photography" element={<PhotographyPage mode={mode} onFullscreenChange={handleFullscreenChange} />} />
        <Route path="/info" element={<InfoPage mode={mode} />} />
        <Route path="/creations" element={<ProjectPage mode={mode} />} />
        <Route path="/project/:id" element={<ProjectPageLayout mode={mode} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy mode={mode} />} />
      </Routes>
    </div>
  );
};

export default App;
