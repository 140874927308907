import PropTypes from "prop-types";
import React from "react";

interface Props {
  placeholder?: string;
  label?: string;
  showDivider?: boolean;
  mode: "dark" | "light";
  className?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string; // Add this line
}

export const Input = ({
  placeholder = "Enter your email address",
  label = "Email:",
  showDivider = true,
  mode,
  className = "",
  value,
  onChange,
  name, // Add this line
}: Props): JSX.Element => {
  return (
    <div
      className={`w-[484px] flex flex-col items-start gap-4 relative 
        ${mode === "light" ? "bg-[#f6f6f6]" : "bg-[#181818]"} ${className}`}
    >
      <div className="w-full flex items-start gap-2 relative">
        {/* Label */}
        <label
          className={`relative text-base font-medium tracking-[0] leading-normal whitespace-nowrap overflow-hidden text-ellipsis 
            ${mode === "light" ? "text-[#181818]" : "text-white"}
            [font-family:'Inter-Medium',Helvetica] mt-[-1px]`}
        >
          {label}
        </label>

        {/* Input placeholder */}
        <input
          type="text"
          name={name} // Add this line
          className={`flex-1 text-base font-normal tracking-[0] leading-normal overflow-hidden text-ellipsis whitespace-nowrap 
            [font-family:'Inter-Regular',Helvetica] bg-transparent outline-none mt-[-1px]
            ${mode === "light" ? "text-[#7f7f7f]" : "text-[#7f7f7f80]"}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>

      {/* Divider line */}
      {showDivider && (
        <div
          className="w-full h-px mb-[-1px] relative"
          style={{
            backgroundColor: "#C5C5C5",
            opacity: 0.1,
            borderTop: "1px solid #C5C5C5",
            position: "relative",
            transformOrigin: "center",
          }}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  showDivider: PropTypes.bool,
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string, // Add this line
};

export default Input;
