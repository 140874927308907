import React from "react";

interface Button1Props {
  text: string; // Button label
  mode: "dark" | "light"; // Light or dark mode styling
  type?: "button" | "submit" | "reset"; // Form button type
  className?: string; // Additional CSS classes
  iconRight?: boolean; // Optionally include the right arrow icon
}

const Button1: React.FC<Button1Props> = ({
  text,
  mode,
  type = "button", // Default to a regular button
  className = "",
  iconRight = true, // Default to showing the arrow icon
}) => {
  // Define mode-dependent styles
  const baseStyles = `inline-flex items-center gap-2 justify-center px-8 py-4 h-[60px] rounded-[9px] transition-all duration-200 shadow-md ${className}`;
  const lightModeStyles = "bg-gradient-to-b from-gray-200 to-gray-300 text-[#181818] hover:from-gray-300 hover:to-gray-400";
  const darkModeStyles = "bg-gradient-to-b from-gray-700 to-gray-800 text-white hover:from-gray-600 hover:to-gray-700";

  return (
    <button
      type={type} // Allows submission for forms
      className={`${baseStyles} ${mode === "dark" ? darkModeStyles : lightModeStyles}`}
    >
      <span className="text-lg font-medium">{text}</span>
      {iconRight && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
        >
          <path
            d="M10.1396 16C11.6015 14.9379 12.9109 13.6929 14.0339 12.2979C14.1749 12.1226 14.1749 11.8774 14.0339 11.7021C12.9109 10.3071 11.6015 9.06206 10.1396 8"
            stroke={mode === "dark" ? "white" : "#181818"} // Adjust icon color based on mode
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </button>
  );
};

export default Button1;
