import PropTypes from "prop-types";
import React from "react";

interface Props {
  size: "web" | "mobile";
  mode: "dark" | "light";
  className?: string; // Optional className for additional styling
  text: string; // Title text prop
  subtext: string; // Subtitle text prop
}

export const Header = ({ size, mode, className = "", text, subtext }: Props): JSX.Element => {
  const isMobile = size === "mobile";
  const isLightMode = mode === "light";

  return (
    <div className={`flex flex-col items-start gap-2 ${className}`}>
      {/* Header title */}
      <div
        className={`font-bold leading-none text-left ${isMobile ? "w-full text-5xl" : "text-[80px]"} ${
          isLightMode ? "text-[#181818]" : "text-white"
        }`}
        style={{ fontFamily: "Inter-Bold, Helvetica" }}
      >
        {text}
      </div>

      {/* Subheading text */}
      <p
        className={`font-normal leading-none text-left ${isMobile ? "w-full text-base" : "text-2xl"} ${
          isLightMode ? "text-[#7f7f7f78]" : "text-[#7f7f7f80]"
        }`}
        style={{ fontFamily: "Inter-Regular, Helvetica" }}
      >
        {subtext}
      </p>
    </div>
  );
};

Header.propTypes = {
  size: PropTypes.oneOf(["web", "mobile"]).isRequired,
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
};

export default Header;
