import React from 'react';
import BackgroundGradient from '../components/BackgroundGradient';
import Frame from '../components/Frame';

interface Props {
  mode: "dark" | "light";
}

const HomePage = ({ mode }: Props): JSX.Element => {
  return (
    <div className={`w-screen h-screen relative overflow-hidden ${mode === "dark" ? "bg-[#1e1e1e]" : "bg-[#f9f9f9]"}`}>

      {/* Background Gradient */}
      <BackgroundGradient className="absolute top-[441px] left-0 w-full z-0 pointer-events-none" />

      {/* Main Content */}
      <div className="relative z-10 flex items-center justify-center h-full">
        {/* Main Frame Content */}
        <Frame />
      </div>

      {/* Links Web Section
      <LinksWeb mode={mode === "dark" ? "light" : "dark"} className="fixed inset-0 z-20 pointer-events-auto" />  */}
    </div>
  );
};

export default HomePage;
