import React from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
  text: string;
  variant: "primary" | "text" | "secondary";
  mode: "dark" | "light";
  className?: string;
  onClick?: () => void;
  link?: string; // Optional link prop
  external?: boolean; // Determines if link opens in a new tab
  iconRight?: boolean; // Icon arrow on the right
  type?: "button" | "submit" | "reset"; // Type for form buttons
}

const Button: React.FC<ButtonProps> = ({
  text,
  variant,
  mode,
  className = "",
  onClick,
  link,
  external,
  iconRight = true,
  type = "button", // Default to "button"
}) => {
  const isInternal = link?.startsWith("/") ?? false; // Determine if the link is internal
  const isExternal = external ?? !isInternal; // Infer if it's external

  // Dynamic styles
  const textColor =
    variant === "primary"
      ? "text-white"
      : mode === "light" && variant === "secondary"
      ? "text-[#181818]"
      : variant === "text"
      ? "text-[#0D0D0D]"
      : "text-[#7f7f7f]";

  const iconColor =
    variant === "primary" ? "white" : mode === "light" ? "#181818" : "#7f7f7f";

  const baseStyles = `inline-flex items-center gap-2 justify-center relative rounded-[9px] px-8 py-4 h-[60px] ${className}`;
  const primaryStyles = "bg-gradient-to-b from-gray-600 to-gray-800 text-white shadow-lg";
  const secondaryStyles = `${mode === "dark" ? "bg-[#181818]" : "bg-[#f3f3f3]"}`;
  const textStyles = "bg-transparent";

  // Content of the button
  const buttonContent = (
    <>
      <span className={`text-lg font-medium ${textColor}`}>{text}</span>
      {iconRight && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
        >
          <path
            d="M10.1396 16C11.6015 14.9379 12.9109 13.6929 14.0339 12.2979C14.1749 12.1226 14.1749 11.8774 14.0339 11.7021C12.9109 10.3071 11.6015 9.06206 10.1396 8"
            stroke={iconColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );

  // Render external link
  if (isExternal && link) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={`${baseStyles} ${
          variant === "primary"
            ? primaryStyles
            : variant === "secondary"
            ? secondaryStyles
            : textStyles
        }`}
      >
        {buttonContent}
      </a>
    );
  }

  // Render internal link
  if (isInternal && link) {
    return (
      <Link
        to={link}
        className={`${baseStyles} ${
          variant === "primary"
            ? primaryStyles
            : variant === "secondary"
            ? secondaryStyles
            : textStyles
        }`}
      >
        {buttonContent}
      </Link>
    );
  }

  // Render button element
  if (onClick) {
    return (
      <button
        onClick={onClick}
        type={type} // Support "submit", "reset", or "button"
        className={`${baseStyles} ${
          variant === "primary"
            ? primaryStyles
            : variant === "secondary"
            ? secondaryStyles
            : textStyles
        }`}
      >
        {buttonContent}
      </button>
    );
  }

  // No valid handler
  return null;
};

export default Button;
