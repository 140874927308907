const BackgroundBlur = () => {
    return (
      <div className="absolute w-full h-[550px] top-0 left-0">
        <div className="relative w-[1012px] h-[263px] top-[-83px] left-[231px]">
          <div className="absolute w-[599px] h-[138px] top-[21px] left-[413px] bg-[#ffb7006e] rounded-[299.32px/69.06px] blur-[236.16px]" />
          <div className="absolute w-[599px] h-[263px] top-0 left-[234px] bg-[#e83f3f6e] rounded-[299.32px/131.67px] blur-[236.16px]" />
          <div className="absolute w-[599px] h-[179px] top-[21px] left-0 bg-[#3f64e86e] rounded-[299.32px/89.32px] blur-[236.16px]" />
        </div>
      </div>
    );
  };
  
  export default BackgroundBlur;
