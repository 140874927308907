import React from "react";
import moon from "../assets/svg/Moon.svg";
import sun from "../assets/svg/Sun.svg";

interface ToggleProps {
  mode: "dark" | "light";
  onClick: () => void;
  className?: string;
  hidden?: boolean;
}

export const Toggle = ({ mode, onClick, className, hidden = false }: ToggleProps): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className={`fixed top-7 transform -translate-x-1/2 w-[62px] left-[calc(50%+19px)] h-[24px] cursor-pointer z-50 pointer-events-auto ${
        hidden ? "hidden" : ""
      } ${className || ""}`}
    >
      <img
        className="w-full h-full object-cover"  // Ensures 24px size
        alt={mode === "dark" ? "Moon icon" : "Sun icon"}
        src={mode === "dark" ? moon : sun}
      />
    </div>
  );
};

export default Toggle;
