import PropTypes from "prop-types";
import React from "react";

interface Props {
  placeholder?: string;
  mode: "dark" | "light";
  className?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
}

export const TextArea = ({
  placeholder = "Write your message here",
  mode,
  className = "",
  value,
  onChange,
}: Props): JSX.Element => {
  return (
    <div
      className={`w-[918px] h-[477px] p-6 overflow-hidden rounded-xl relative flex 
        ${mode === "dark" ? "shadow-[0px_19px_30px_#00000033] bg-[#0d0d0d]" : "bg-[#f3f3f3]"} ${className}`}
    >
      <textarea
        className={`w-full h-full resize-none overflow-hidden text-ellipsis 
          text-base font-normal tracking-[var(--inter-16-regular-letter-spacing)] leading-[var(--inter-16-regular-line-height)]
          [font-family:'Inter-Regular',Helvetica] transition-all duration-[0.2s] ease-[ease-in-out]
          ${mode === "light" ? "text-[#7f7f7f]" : "text-[#7f7f7f80]"} bg-transparent outline-none 
          [-webkit-line-clamp:14] [-webkit-box-orient:vertical]`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextArea;
