import React from "react";
import previewImage from "../assets/png/938de143-06f0-4b4f-be18-86a1f94d8a44 3_preview_rev_1 1.png";
import ellipse73 from "../assets/svg/Ellipse73.svg";

const Frame = (): JSX.Element => {
  return (
    <div className="w-screen h-screen relative flex items-center justify-center overflow-hidden">
      
      {/* Ellipse: Centered both vertically and horizontally with larger size */}
      <img
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70vw] h-[70vw] max-w-[570px] max-h-[570px]"
        alt="Ellipse"
        src={ellipse73}
      />
      
      {/* Text Block: Positioned left, near the top-left corner of the circle */}
      {/* Text Block: Positioned left, near the top-left corner of the circle */}
<div className="absolute top-[20%] left-[12%] max-w-[300px] text-left px-4">
  <p className="font-inter-24-medium text-basicdark-gray leading-7">
    Welcome to my digital space!<br /><br />
    Here, you’ll find my portfolio showcasing design projects and some analog photography.<br /><br />
    I’m a UI/UX Engineer with a strong technical background and a passion for creating seamless, human-centered digital experiences. I’m now based in the cold but beautiful Finland.<br /><br />
    Let’s connect and collaborate!
  </p>
</div>

      
      {/* Image: Positioned at the bottom, centered horizontally, scales responsively */}
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[32vw] min-w-[150px] max-w-[600px]">
        <img
          className="w-full h-auto object-cover"
          alt="Portrait"
          src={previewImage}
        />
      </div>
    </div>
  );
};

export default Frame;

