// PhotographyPage.tsx
import React, { useState, useEffect } from 'react';
import './PhotographyPage.css';
import imagesData from './images.json';
import Footer from '../components/Footer'
import Header from '../components/Header'
import BackgroundBlur from '../components/BackgroundBlur';

interface PhotographyPageProps {
  mode: "dark" | "light";
  onFullscreenChange: (isFullscreen: boolean) => void; // New callback prop
}

const PhotographyPage: React.FC<PhotographyPageProps> = ({ mode, onFullscreenChange }) => {
  const [images, setImages] = useState<any[]>([]);
  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null);

  useEffect(() => {
    setImages(imagesData);
  }, []);

  const openFullscreen = (imagePath: string) => {
    setFullscreenImage(imagePath);
    onFullscreenChange(true); // Notify App that fullscreen is open
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
    onFullscreenChange(false); // Notify App that fullscreen is closed
  };

  return (
<div className="photography-page">

<BackgroundBlur />

<div className="container mx-auto py-32 flex items-start max-w-screen-lg">

          <Header
            mode={mode}
            size="web"
            text="Analog Memories"
            subtext="Some pictures I enjoyed taking"
          />
        </div>
      
      {/* Image Gallery */}
      <div className="photography-gallery z-10">
        {images.map((image) => (
          <div
            key={image.id}
            className="gallery-item"
            onClick={() => openFullscreen(`${image.prefix}.${image.suffix}`)}
          >
            <img
              loading="lazy"
              src={`${image.prefix}.${image.suffix}`}
              alt={`Photography by Lars Oberhofer - ${image.prefix.split('/').pop()}`}
              className="gallery-image"
            />
          </div>
        ))}
      </div>

      {/* Fullscreen Modal */}
      {fullscreenImage && (
        <div className="fullscreen-modal open z-40" onClick={closeFullscreen}>
          <button className="close-button z-50" onClick={closeFullscreen}>&times;</button>
          <img src={fullscreenImage} alt="Fullscreen" className="fullscreen-image z-40" />
        </div>
      )}
          <Footer mode={mode} className="w-full mt-auto" />

    </div>

  );
};

export default PhotographyPage;
