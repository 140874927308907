import React, { useState, useEffect } from "react";
import Button from "./Button";

const CookieBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const userConsent = localStorage.getItem("cookieConsent");
    try {
      const consent = userConsent ? JSON.parse(userConsent) : null;
      if (!consent) {
        setIsVisible(true);
      }
    } catch (error) {
      console.error("Invalid consent data in localStorage:", error);
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    const consentData = {
      status: "accepted",
      timestamp: new Date().toISOString(),
    };
    localStorage.setItem("cookieConsent", JSON.stringify(consentData));
    setIsVisible(false);
  };

  const handleNecessary = () => {
    const consentData = {
      status: "necessary",
      timestamp: new Date().toISOString(),
    };
    localStorage.setItem("cookieConsent", JSON.stringify(consentData));
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50">
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-80"></div>

      {/* Cookie Banner Content */}
      <div     className="absolute left-0 w-full flex justify-center"
    style={{ top: "70%" }}> 
        
        <div className="w-full max-w-xl bg-gray-800 text-white p-6 rounded-lg shadow-lg z-10">
          <p className="mb-4 text-sm text-center">
          I want to use cookies to improve the browsing experience and analyze my traffic. Read my{" "}
            <a
              href="/privacy-policy"
              className="text-blue-400 underline hover:text-blue-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            to learn more.
          </p>
          <div className="flex justify-center gap-4">
            <Button
              text="Yes, I’m Okay with All Cookies"
              variant="primary"
              mode="dark"
              onClick={handleAccept}
            />
            <a
              onClick={handleNecessary}
              className="text-sm font-medium text-gray-300 underline hover:text-gray-400 cursor-pointer"
            >
              Only Necessary
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
