import PropTypes from "prop-types";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";

interface Props {
  mode: "dark" | "light";
  className?: string;
  hidden?: boolean;
}

export const LinksWeb = ({ mode, className, hidden = false }: Props): JSX.Element => {
  const location = useLocation();
  const { scrollY } = useScroll();

  // Create fade-out animation based on scroll progress
  const opacity = useTransform(scrollY, [0, 400], [1, 0]);
  const translateY = useTransform(scrollY, [0, 400], [0, -50]);

  // Function to determine if the current link is active
  const isActive = (path: string) => location.pathname === path;

  const linkStyles = `pointer-events-auto font-inter-bold text-36-extra-bold fixed leading-normal transition-transform duration-300 px-4 py-2 rounded-lg
    ${
      mode === "dark"
        ? "text-basiconyx bg-[rgba(255,255,255,0.1)]"
        : "text-white bg-[rgba(0,0,0,0.2)]"
    }
    backdrop-blur-md hover:backdrop-blur-lg`;

  const gradientMask = {
    maskImage: "linear-gradient(180deg, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))",
    WebkitMaskImage:
      "linear-gradient(180deg, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))",
    maskRepeat: "no-repeat",
    WebkitMaskRepeat: "no-repeat",
    maskSize: "100% 100%",
    WebkitMaskSize: "100% 100%",
  };

  return (
    <motion.div
      style={{ opacity, translateY }} // Attach Framer Motion styles
      className={`${hidden ? "hidden" : ""} fixed inset-0 z-50 pointer-events-none ${className || ""}`}
    >
      {/* Portfolio Link - Top Right */}
      <div
        className={`${linkStyles} top-4 right-8 ${
          isActive("/creations") ? "underline" : "hover:scale-105"
        }`}
        style={gradientMask} // Apply gradient mask here
      >
        <Link to="/creations">PORTFOLIO</Link>
      </div>

      {/* Lars Oberhofer Link - Top Left */}
      <div
        className={`${linkStyles} top-4 left-8 ${
          isActive("/") ? "underline" : "hover:scale-105"
        }`}
        style={gradientMask} // Apply gradient mask here
      >
        <Link to="/">LARS OBERHOFER</Link>
      </div>

      {/* Get In Touch Link - Bottom Left */}
      <div
        className={`${linkStyles} bottom-4 left-8 ${
          isActive("/info") ? "underline" : "hover:scale-105"
        }`}
        style={gradientMask} // Apply gradient mask here
      >
        <Link to="/info">GET IN TOUCH</Link>
      </div>

      {/* Photography Link - Bottom Right */}
      <div
        className={`${linkStyles} bottom-4 right-8 ${
          isActive("/photography") ? "underline" : "hover:scale-105"
        }`}
        style={gradientMask} // Apply gradient mask here
      >
        <Link to="/photography">PHOTOGRAPHY</Link>
      </div>
    </motion.div>
  );
};

LinksWeb.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]),
  hidden: PropTypes.bool,
};

export default LinksWeb;
