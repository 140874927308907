import React, { useState } from "react";
import Button from "./Button";
import PDFViewer from "./PDFviewer"; // Ensure this is correctly imported

interface ProjectCardProps {
  title: string;
  description: string;
  logoSrc: string;
  imageSrc: string;
  darkMode: boolean;
  customImageStyle?: React.CSSProperties;
  link?: string; // Optional link prop
  external?: boolean; // Added external prop
  note?: string; // Optional note
  pdfLink?: string; // Optional PDF file link
  videoLink?: string; // Optional YouTube video link
  buttonText?: string; // Optional text for the "link" button
}

const isExternalLink = (url?: string): boolean =>
  url ? !url.startsWith("/") && url.includes("http") : false;


const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  description,
  logoSrc,
  imageSrc,
  darkMode,
  customImageStyle,
  note,
  link,
  pdfLink,
  videoLink,
  buttonText = "Learn More", // Default button text
  external = link ? isExternalLink(link) : false, // Explicitly handle undefined link
  
}) => {
  const [showPDF, setShowPDF] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const handleViewPDF = () => setShowPDF(true);
  const handleViewVideo = () => setShowVideo(true);

  return (
    <div
      className={`relative w-full max-w-[1024px] h-[565px] mx-auto rounded-2xl overflow-hidden border ${
        darkMode ? "shadow-card-dark" : "shadow-card-light"
      }`}
      style={{ backgroundColor: "transparent" }}
    >
      {/* Image Section */}
      <div className="absolute top-0 right-0 w-[457px] h-full" style={customImageStyle}>
        <img src={imageSrc} alt={title} className="w-full h-full object-cover" />
      </div>

      {/* Content Section */}
      <div className="absolute top-[60px] left-[60px] flex flex-col justify-between max-w-[503px] h-[445px] z-10">
      <div className="flex flex-col gap-4">
          <div className="w-[70px] h-[70px] rounded-2xl overflow-hidden shadow-md">
            <img src={logoSrc} alt={`${title} logo`} className="w-full h-full object-contain" />
          </div>
          <h2
            className={`font-extrabold text-2xl ${
              darkMode ? "text-white" : "text-[#181818]"
            } line-clamp-2`}
          >
            {title}
          </h2>
          <p
            className={`font-normal text-base ${
              darkMode ? "text-[#7f7f7f]" : "text-[#7f7f7f78]"
            } line-clamp-6`}
          >
            {description}
          </p>

          {/* Render the note if it exists */}
          {note && (
            <p
              className={`font-medium text-sm mt-4 ${
                darkMode ? "text-[#c4c4c4]" : "text-[#7f7f7f]"
              }`}
            >
              {note}
            </p>
          )}
        </div>


        {/* Buttons Section */}
        <div className="mt-auto flex gap-4">
          {videoLink && (
            <Button
              text="Watch Video"
              variant="primary"
              mode={darkMode ? "dark" : "light"}
              onClick={handleViewVideo}
              className="mt-4"
            />
          )}
          {link && (
            <Button
              text={buttonText} // Use the custom button text
              variant="primary"
              mode={darkMode ? "dark" : "light"}
              iconRight={true}
              link={link}
              className="mt-4"
            />
          )}
          {pdfLink && (
            <Button
              text="View PDF"
              variant="text"
              mode={darkMode ? "dark" : "light"}
              onClick={handleViewPDF}
              className="mt-4"
            />
          )}


        </div>
      </div>

      {/* PDF Viewer (Inline) */}
      {showPDF && pdfLink && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center"
          onClick={() => setShowPDF(false)} // Close on click outside
        >
          <div className="bg-white w-3/4 h-3/4 p-4 rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
            <PDFViewer fileUrl={pdfLink} />
            <Button
              text="Close PDF"
              variant="secondary"
              mode={darkMode ? "dark" : "light"}
              onClick={() => setShowPDF(false)}
              className="mt-4"
            />
          </div>
        </div>
      )}

      {/* Video Viewer (Inline) */}
      {showVideo && videoLink && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center"
          onClick={() => setShowVideo(false)} // Close on click outside
        >
          <div className="bg-white w-3/4 h-3/4 p-4 rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${new URL(videoLink).searchParams.get("v")}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <Button
              text="Close Video"
              variant="secondary"
              mode={darkMode ? "dark" : "light"}
              onClick={() => setShowVideo(false)}
              className="mt-4"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
