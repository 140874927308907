import React from "react";
import meWorking from "../assets/JPG/me-working.jpg";
import beefedupraspberry from "../assets/JPG/beefedupraspberry.jpg";
import pattern from "../assets/JPG/patterns.jpg";
import { motion, useScroll } from "framer-motion";

import keller from "../assets/JPG/keller.jpg";
import newFrequency from "../assets/JPG/new-frequency-1.jpg";
import snr from "../assets/JPG/SNR.jpg";


import wieseCar from "../assets/JPG/wiese-car.jpg";
import wieseDevices from "../assets/JPG/wiese-devices.jpeg";
import logoVolocopter from "../assets/svg/projects/Volocopter.svg"; // Logo import

interface BachelorThesisCardProps {
  mode: "dark" | "light";
}

const BachelorThesisCard: React.FC<BachelorThesisCardProps> = ({ mode }) => {
  const bgColor = mode === "dark" ? "bg-[#0d0d0d]" : "bg-[#f9f9f9]";
  const textColor = mode === "dark" ? "text-[#7f7f7f]" : "text-[#181818]";
  const headingColor = mode === "dark" ? "text-white" : "text-[#181818]";
  const borderColor = mode === "dark" ? "border-[#383737]" : "border-[#ebebeb]";

  const { scrollYProgress } = useScroll();



  return (

    <>
    {/* Scroll Progress Bar */}
    <motion.div
      className="fixed top-0 left-0 h-full w-2 bg-orange-500 z-50"
      style={{ scaleY: scrollYProgress, transformOrigin: "top" }}
    />

    {/* Main Content */}
    <motion.div
      className=" mx-auto"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >

<div
  className={`w-full max-w-[1024px] mx-auto rounded-2xl overflow-hidden border ${borderColor} ${
    mode === "dark" ? "shadow-card-dark" : "shadow-card-light"
  } ${bgColor}`}
>

  {/* Header Section */}
  <div className="flex flex-col gap-8 p-[60px]">
    {/* Logo and Title Section */}
    <div className="flex items-start gap-6">
      {/* Logo */}
      <div className="w-[70px] h-[70px] flex-shrink-0 rounded-2xl overflow-hidden flex items-center justify-center bg-[#383737] shadow-md">
        <img
          src={logoVolocopter}
          alt="Volocopter Logo"
          className="object-contain w-full h-full"
        />
      </div>

      {/* Title */}
      <div className="flex-1">
        <h1
          className={`font-extrabold text-4xl leading-[1.2] ${headingColor}`}
        >
          Optimizing the large-volume data transfer between
          the landing eVTOL and the ground infrastructure
          using Wi-Fi
        </h1>
      </div>
    </div>
  </div>
















      {/* Content Sections */}
      <div className={`flex flex-col  pb-[60px] px-[60px] ${textColor}`}>





























    <div className="flex flex-col w-[904px] items-start gap-[30px] relative">
      <p className="relative self-stretch mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px]">
        <span className="font-bold">
          Overview
          <br />
        </span>

        <span className="[font-family:'Inter-Regular',Helvetica]">
          As part of my Bachelor Thesis in Electrical Engineering at the
          University of Twente, I collaborated with Volocopter GmbH to optimize
          air-to-ground data communication for eVTOL (electric vertical take-off
          and landing) aircraft. My research focused on leveraging the newly
          legislated{" "}
        </span>

        <span className="font-bold">6 GHz Wi-Fi 6E (802.11ax)</span>

        <span className="[font-family:'Inter-Regular',Helvetica]">
          {" "}
          bandwidth to address real-world challenges in urban air mobility.
          These challenges included ensuring high-throughput, reliable data
          transfer in environments prone to interference and multipath
          propagation.
        </span>
      </p>

      <img
        className="relative self-stretch w-full h-[529px] object-cover"
        alt="Me working"
        src={meWorking}
      />
    </div>













{/* What I Did Section */}
<section className="mt-12">
<h2
    className={`text-lg font-semibold mb-4 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
    WHAT I DID
  </h2>
  <div className="flex items-center gap-10">
    {/* Text Section */}
    <div className="flex-1">
      {/* Design Thinking Section */}
      <p className="text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal">
        <span className="font-bold">
          Design Thinking Applied to Communication Challenges
          <br />
        </span>
      </p>
      <ul className="list-disc pl-5 text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal mb-4">
        <li>
          Investigated how the newly legislated{" "}
          <span className="font-bold">6 GHz frequency band</span> with advanced
          features like <span className="font-bold">4x4 MIMO</span> could
          enhance data throughput and reliability for air-to-ground
          communication.
        </li>
        <li>
          Conducted both simulated and real-world tests using{" "}
          <span className="font-bold">Raspberry Pi Compute Modules</span> to
          validate performance under various conditions.
        </li>
      </ul>

      {/* System Optimization Section */}
      <p className="text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal">
        <span className="font-bold">System Optimization with Advanced Tools</span>
      </p>
      <ul className="list-disc pl-5 text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal mb-4">
        <li>
          Utilized tools like <span className="font-bold">Ansys HFSS</span> to
          simulate antenna configurations and evaluate performance metrics such
          as radiation patterns, directional gain, and signal integrity in
          complex urban environments.
        </li>
        <li>
          Designed and optimized antenna placement to mitigate{" "}
          <span className="font-bold">multipath interference</span> and enhance
          communication reliability.
        </li>
      </ul>

      {/* User-Centric Insights Section */}
      <p className="text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal">
        <span className="font-bold">User-Centric Insights</span>
      </p>
      <ul className="list-disc pl-5 text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal">
        <li>
          Evaluated throughput performance under different conditions, including
          altitude, distance, and environmental reflections.
        </li>
        <li>
          Analyzed various{" "}
          <span className="font-bold">
            modulation schemes (QPSK to 1024-QAM)
          </span>{" "}
          and frequency bands (2.4 GHz, 5 GHz, and 6 GHz) to determine
          configurations that maximize reliability and minimize interference.
        </li>
      </ul>
    </div>

    {/* Image Section */}
    <img
      className="shadow-lg w-[321px] h-[353px] object-cover"
      alt="Beefed-up Raspberry Pi setup"
      src={beefedupraspberry}
    />
  </div>
</section>







  <section className="mt-12">    
  <h2 className={`  class="text-lg font-semibold mb-6 text-[#181818]" ${mode === "dark" ? "text-white" : "text-[#181818]"
    }`}>TAKING IT TO THE TEST
  </h2>

  {/* Container to limit content width */}
  <div className="flex flex-col gap-6" style={{ maxWidth: "905px" }}>
    {/* Image Grid */}
    <div
      className="flex justify-between items-start gap-[41px]"
      style={{ width: "904px", height: "305px" }}
    >
      {/* Image 1 */}
      <div className="flex-shrink-0 w-[349px] h-[305px]">
        <img
          src={keller}
          alt="Testing in isolated environments"
          className="shadow-lg object-cover w-full h-full"
        />
      </div>

      {/* Image 2 */}
      <div className="flex-shrink-0 w-[513px] h-[305px]">
        <img
          src={newFrequency}
          alt="Frequency tests"
          className="shadow-lg object-cover w-full h-full"
        />
      </div>
    </div>

    {/* Text Content */}
    <p className="w-[904px] [font-family:'Inter-Bold',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px]">
      <span className="font-bold">
        Real-World Testing
        <br />
      </span>

      <span className="[font-family:'Inter-Regular',Helvetica]">
        Testing the 6 GHz frequency band required careful isolation from other
        Wi-Fi networks, as country-specific beacon signals initially restricted
        access. I applied{" "}
      </span>

      <span className="font-bold">Linux kernel patches</span>

      <span className="[font-family:'Inter-Regular',Helvetica]">
        {" "}
        to configure a PCIe-accessible Raspberry Pi Compute Module to enable
        controlled testing in a cellar environment. This setup validated signal
        integrity, throughput, and interference mitigation strategies.
      </span>
    </p>
  </div>








 <div className="flex flex-wrap w-[904px]  mt-12 items-start gap-[40px_40px] relative">
      <img
        className="relative w-56 h-[298px] object-cover"
        alt="Wiese devices"
        src={wieseDevices}
      />

      <img
        className="relative w-56 h-[298px] object-cover"
        alt="Wiese car"
        src={wieseCar}
      />

      <p className="relative w-[376px] mt-[-1.00px] [font-family:'Inter-Bold',Helvetica] font-normal text-[#7f7f7f] text-[15px] tracking-[0] leading-[23.7px]">
        <span className="font-bold">
          Field Test
          <br />
        </span>

        <span className="[font-family:'Inter-Regular',Helvetica]">
          Building on the lab results, the system was deployed on drones for
          realistic, real-world testing. The tests evaluated performance under
          practical eVTOL operating conditions, providing insights into
          throughput, error resilience, and reliability across varying flight
          parameters.
        </span>
      </p>
    </div>

    </section>











{/* Signal-to-Noise Analysis */}
<section className="mt-12"> {/* Add consistent margin between sections */}
  <h2
    className={`text-lg font-semibold mb-6 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
    SIGNAL-TO-NOISE ANALYSIS
    </h2>


  <div
    className="flex items-start gap-[40px]"
    style={{ maxWidth: "904px" }}
  >
    {/* Image Section */}
    <div className="w-[298px] h-[310px] flex-shrink-0">
      <img
        src={snr}
        alt="Frequency spectrum"
        className="object-cover w-full h-full"
      />
    </div>

    {/* Text Section */}
    <div className="flex-1">
      <p className="text-[#7f7f7f] text-sm leading-6">
        This graph illustrates the relationship between{" "}
        <span className="font-bold">Signal-to-Noise Ratio (SNR)</span> and{" "}
        <span className="font-bold">Bit Error Rate (BER)</span> across various
        modulation schemes (QPSK to 1024-QAM) under{" "}
        <span className="font-bold">AWGN</span> and{" "}
        <span className="font-bold">Rician fading</span> conditions.
      </p>
      <p className="text-[#7f7f7f] text-sm leading-6 mt-4">
        In my thesis, BER analysis was used to evaluate the suitability of{" "}
        <span className="font-bold">
          Wi-Fi 6E frequency bands (2.4 GHz, 5 GHz, and 6 GHz)
        </span>{" "}
        for eVTOL communication. Simulated and measured results provided
        insights into optimal configurations for high-throughput, reliable
        communication in challenging environments with multipath propagation.
      </p>
    </div>
  </div>
</section>


{/* Simulated Radiation Patterns Section */}
<section className="mt-12">
  <h2
    className={`text-lg font-semibold mb-6 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
    SIMULATED RADIATION PATTERNS FOR WI-FI 6E FREQUENCY BANDS
    </h2>


    <div className="flex flex-col">
  {/* Image */}
  <div className="mb-6">
    <img
      src={pattern}
      alt="Simulated Radiation Patterns"
      className="w-full h-[223px] object-cover"
    />
  </div>

  {/* Description */}
  <p className="text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal">
    This figure showcases simulated radiation patterns for antennas operating
    at 2.4 GHz, 5 GHz, and 6 GHz, modeled using{" "}
    <span className="font-bold">Ansys HFSS</span>. The simulations evaluated:
  </p>
  <ul className="list-disc pl-5 text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal">
    <li>
      <span className="font-bold">Directional Gain:</span> Critical for
      air-to-ground communication in urban settings.
    </li>
    <li>
      <span className="font-bold">Field Strength and Mutual Coupling:</span>{" "}
      Key parameters for optimizing{" "}
      <span className="font-bold">MIMO configurations</span> and reducing
      interference.
    </li>
  </ul>
  <p className="text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal">
    Insights from these simulations informed antenna placement strategies to
    enable reliable, high-performance Wi-Fi 6E communication for eVTOL
    applications.
  </p>
</div>

</section>



{/* What I Learned Section */}
<section className="mt-12">

  <h2
    className={`text-lg font-semibold mb-6 ${
      mode === "dark" ? "text-white" : "text-[#181818]"
    }`}
  >
WHAT I LEARNED   
 </h2>

  <div className="flex flex-col text-[#7f7f7f]">
    {/* Description */}
    This project strengthened my technical, analytical, and design skills,
        emphasizing the importance of holistic system thinking that balances
        technical feasibility with user needs.
    <ul className="list-disc pl-5 text-[#7f7f7f] text-[15px] leading-[23.7px] font-normal">
      <li>
        Demonstrated ability to address high-stakes communication challenges
        using advanced wireless technologies.
      </li>
      <li>
        Gained in-depth experience with Wi-Fi 6E, applicable to next-gen
        networking solutions like <span className="font-bold">5G</span> and{" "}
        <span className="font-bold">IoT</span>.
      </li>
      <li>
        Developed a strong ability to integrate technical depth with usability
        and design to bridge gaps in developer-driven ecosystems.
      </li>
    </ul>

    {/* Note Section */}
    <div className="flex flex-col items-center justify-center gap-2.5 p-5">
      <p className="text-[15px] leading-[23.7px] font-normal italic">
        <span className="font-bold italic">Note:</span>{" "}
        <br/>
        This specific outcomes of the research are protected and will be available to the public by the
        beginning of 2026.
      </p>
    </div>
  </div>
</section>

      </div>
    </div>
    </motion.div>
    </>
  );
};

export default BachelorThesisCard;
