import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

interface PDFViewerProps {
  fileUrl: string;
  className?: string; // Add className as an optional prop
}
 
const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl, className }) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  return (
    <div className={`pdf-viewer ${className}`} style={{ height: "600px", display: "flex", flexDirection: "column" }}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        {fileUrl ? (
          <>
            {/* Viewer with zoom plugin */}
            <Viewer fileUrl={fileUrl} plugins={[zoomPluginInstance]} />
            {/* Optional Zoom Controls */}
            <div className="flex gap-2 justify-center mt-2">
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
            </div>
          </>
        ) : (
          <p className="text-red-500">Error: PDF file not found or invalid.</p>
        )}
      </Worker>
    </div>
  );
};

export default PDFViewer;
